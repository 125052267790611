import React from 'react';
import Navbar from '../Components/Navbar';
import PracticesNav from '../Components/PracticesNav';


const PracticesPage = () => {

  return (
    <div>
      <>
         <Navbar />
        <PracticesNav />
        </>  
    </div>
  )
}

export default PracticesPage

